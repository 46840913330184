import React, {useContext} from "react"
import {Row, Col, Container, Button} from "react-bootstrap"
import UnauthenticatedLayout from "../components/layouts/unauthenticatedLayout";
import {navigate} from "gatsby";
import {GlobalStateContext} from "../context/GlobalContextProvider";

const IndexPage = () => {
    const state = useContext(GlobalStateContext);
    const registerbutton = !state.mobile ?
        <Row className="justify-content-center" key={"register-row"}>
            <Col md="6">
                <Button key={"register-button"} className="half-opacity" variant="light" size="lg" block
                        onClick={() => navigate("/register/")}>Register</Button>
            </Col>
        </Row>
        : <Row className="justify-content-center"  key={"license-row"}>
            <Col md="6">
                <Button key={"license-button"} className="half-opacity" variant="light" size="lg" block href={"http://seventeendays.org/"} >Learn More</Button>
            </Col>
        </Row>;
    return (
        <UnauthenticatedLayout title="Welcome to Seventeen Days!">
            <Container className="text-center" id="home">
                <Row className="justify-content-center" key={"login-row"}>
                    <Col md="6">
                        <Button key={"login-button"} variant="primary" size="lg" block
                                onClick={() => navigate("/login/")}>Login</Button>
                    </Col>
                </Row>

                <Row className="justify-content-center" key={"preview-row"}>
                    <Col md="6">
                        <Button key={"preview-button"} className="half-opacity" variant="light" size="lg" block
                                onClick={() => navigate("/preview/")}>Preview</Button>
                    </Col>
                </Row>
                {registerbutton}
            </Container>
        </UnauthenticatedLayout>
    )
}

export default IndexPage
