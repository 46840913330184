import React from "react"
import { redirectTo } from "@reach/router"
import userService from "../../services/user";
import {Col, Container, Row} from "react-bootstrap";
import CustomNavbar from "../navBar";
import { navigate } from "gatsby"
import logo from "../../images/logo.png";
import Helmet from 'react-helmet'

type UnauthenticatedLayoutProps = {
    children: any,
    title: string
}
class UnauthenticatedLayout extends React.Component<UnauthenticatedLayoutProps> {

    readonly children: any;
    readonly title: string;
    isLoggedIn: boolean = false;
    
    constructor(props: UnauthenticatedLayoutProps)
    {
        super(props);
        this.children = props.children;
        this.title = props.title;
    }

    componentDidMount() {
        if (userService.isLoggedIn()) {
            navigate("/main/")
        }
    }

    render() {
        
        return (
            <>
                <Helmet>
                    <title>{this.title}</title>
                    <body className="unauthenticated"/>
                </Helmet>
                <Container fluid className="px-0 main" id="intro_layout">
                    <img src={logo} alt="SeventeenDays" style={{height:'20vh'}} />
                    {this.children}
                </Container>
            </>
        )
    }
}

export default UnauthenticatedLayout